<template>
  <div :id="id" ref="root" class="is-wide container scroll-mt-64">
    <div
      class="items-start border-b border-b-gray-300 py-32 md:grid md:grid-cols-[auto_1fr] md:py-48 lg:py-56"
    >
      <BlokkliField
        :list="paragraphsHeader"
        name="field_paragraphs_header"
        field-list-type="nav_title"
        class="md:w-[200px] lg:w-[230px] xl:w-[460px]"
        :class="{
          'md:sticky md:top-64 lg:top-80 mb-24': !isEditing,
        }"
      />
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="paragraph-nav-section-list"
        :allowed-fragments="[
          'pension_fund_interest_rates',
          'current_funded_ratio',
          'parity_commission_tenure',
        ]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphNavSectionFragment } from '#graphql-operations'

const { $clientRects } = useNuxtApp()

const props = defineProps<{
  navSectionTeaser?: ParagraphNavSectionFragment['navSectionTeaser']
  paragraphs?: ParagraphNavSectionFragment['paragraphs']
  paragraphsHeader?: ParagraphNavSectionFragment['paragraphsHeader']
}>()

const { uuid, isEditing } = defineBlokkli({
  bundle: 'nav_section',
  editor: {
    addBehaviour: 'no-form',
  },
})

defineOptions({
  name: 'ParagraphNavSection',
})

const id = computed(() => props.navSectionTeaser?.anchor || uuid)

onMounted(() => {
  $clientRects.register(id.value)
})

onBeforeUnmount(() => {
  $clientRects.unregister(id.value)
})
</script>

<style lang="postcss">
.paragraph-nav-section-list {
  > *:first-child {
    @apply !mt-0;
  }
}
</style>
